/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from "react"

import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import PageBanner from "../components/atoms/PageBanner/PageBanner"
import ReturnsForm from "../components/organisms/ReturnsForm/ReturnsForm"

const ReturnsRequest = () => {
    return (
        <Layout>
            <SEO title="Returns Request" />
            <PageBanner breadcrumb="Returns" title="Returns request" color="latte" imageSize="medium" />
            <Container size="large">
                <ReturnsForm/>
            </Container>
        </Layout>
    )
  }
  
  export default ReturnsRequest
  